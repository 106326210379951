<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1"> Line Chart </b-card-title>
        <b-card-sub-title>Commercial networks</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        :options="apexChatData.lineAreaChartSpline.chartOptions"
        :series="apexChatData.lineAreaChartSpline.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import flatPickr from 'vue-flatpickr-component';
import apexChatData from './apexChartData';

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  data() {
    return {
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
    };
  },
};
</script>
